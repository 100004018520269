











































































































































































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'Scaffold',
  data() {
    return {
      showAccountMenu: false,
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'auth/getUser',
    }),
  },
  methods: {
    signOut(): void {
      this.$store.dispatch('auth/signOut');
    },
  },
});
