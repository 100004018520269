











































































































































































































































































































import Vue from 'vue';
import Scaffold from '@/components/Scaffold.vue';
import { mapGetters } from 'vuex';
import { formatLongDate } from '@/utils/date_time';
import Tour from '@/dtos/tour';
export default Vue.extend({
  components: { Scaffold },
  data() {
    return {};
  },
  props: {},
  mounted() {
    this.$store.dispatch('tour/bindCurrentTour', this.$route.params.tourId);
    this.$store.dispatch('tour/fetchTourMembers', this.$route.params.tourId);
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      tour: 'tour/getCurrentTour',
      tourMembers: 'tour/getTourMembers',
    }),
  },
  methods: {
    formatDate(input: any): string {
      return formatLongDate(input);
    },
    openMaps(tour: Tour) {
      const { latitude, longitude } = tour.location;
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
      );
    },
    joinTour() {
      // this.$store.dispatch
    },
    removeTour() {
      if (confirm('Möchtest du diese Tour wirklich löschen?')) {
        this.$store.dispatch('tour/removeTour', this.tour);
        this.$router.replace({ name: 'Discover' });
      }
    },
    editTour() {
      this.$router.push({ name: 'Edit Tour' });
    },
    duplicateTour() {
      this.$router.push({ name: 'Duplicate Tour' });
    },
  },
});
